<template>
  <div class="ForgotPassword">
    <div class="header">
      <i
        @click="gootherpage('signin')"
        class="iconfont icon-xiangyoujiantou"
      ></i>
      <div>忘记密码</div>
    </div>
    <div class="user-box">
      <input
        class="name"
        v-model="phone"
        type="text"
        placeholder="请输入手机号"
        oninput="value=value.replace(/[^\d]/g,'')"
        :class="{ 'err-input': iphone.err }"
        maxlength="11"
        @blur="verification()"
      />
      <span v-show="hintShow">{{ hint }}</span>
      <div class="VerificationCode">
        <input placeholder="验证码" v-model="VerCode" type="text" />
        <button
          :class="!isDisabled ? 'code-button' : 'code-button-none'"
          :disabled="isDisabled"
          @click="Sendout"
        >
          {{ buttonName }}
        </button>
      </div>
      <input
        class="password"
        v-model="password"
        placeholder="设置新密码"
        type="password"
      />
      <input
        class="password"
        v-model="password2"
        placeholder="确认新密码"
        type="password"
      />
      <button
        :class="!buttonflag ? 'active-button' : null"
        :disabled="buttonflag"
      >
        确认修改
      </button>
    </div>
    <div class="careful">注册登录即代表同意 《服务协议》 和《隐私政策》</div>
  </div>
</template>

<script>
import { getsendphone } from "@/api";
export default {
  name: "ForgotPassword",
  data() {
    return {
      phone: "",
      password: "",
      password2: "",
      VerCode: "",
      buttonflag: true,
      buttonName: "发送验证码", //初始化文字
      isDisabled: false, //是否禁用
      count: 0,
      hintShow: "",
      hint: "",
      iphone: {
        val: "",
        err: false,
        pass: false,
      },
    };
  },
  methods: {
    verification() {
      console.log('---------');
      const reg = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/;
      if (
        this.phone !="" &&
        !reg.test(this.phone)
      ) {
        this.hintShow = true;
        this.hint = "手机号码格式错误";
        this.iphone.err = true;
        return false;
      } else {
        this.hintShow = false;
        this.hint = "手机号码正确";
        this.iphone.err = false;
        return true;
      }
    },
    gootherpage(value) {
      this.$emit("showotherpage", value);
    },
    Sendout() {
      this.count = 60;
      this.isDisabled = true;
      this.buttonName = "请 " + this.count + " 秒后重试";
      var times = setInterval(() => {
        this.count--; //递减
        this.buttonName = "请 " + this.count + " 秒后重试";
        if (this.count <= 0) {
          // <=0 变成获取按钮
          this.isDisabled = false;
          this.buttonName = "发送验证码";
          clearInterval(times);
        }
      }, 1000); //1000毫秒后执行
      console.log(this.phone);
      getsendphone(this.phone).then(res=>{
        console.log(res);
      })
    },
  },
  computed: {
    userdata() {
      let { phone, password, password2, VerCode } = this;
      return { phone, password, password2, VerCode };
    },
  },
  watch: {
    userdata: function (value) {
      let { phone, password, password2, VerCode } = value;
      if (phone != "" && password != "" && password2 != "" && VerCode != "") {
        this.buttonflag = false;
      } else {
        this.buttonflag = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ForgotPassword {
  position: relative;
  height: 100%;
  width: 100%;
  .header {
    display: flex;
    margin: 0 47px;
    .iconfont {
      margin-top: 44px;
      transform: rotateY(180deg);
      background: rgb(242, 242, 242);
      width: 22px;
      height: 22px;
      border-radius: 50%;
      text-align: center;
      line-height: 24px;
      font-size: 10px;
      cursor: pointer;
      color: black;
    }
    div {
      margin-top: 45px;
      padding-bottom: 21px;
      margin-left: 10px;
    }
  }
  .user-box {
    margin: 15px 40px 0 47px;
    span {
      color: red;
      font-size: 12px;
      margin-left: 5px;
      position: relative;
      top: 5px;
    }
    input {
      outline: none;
      text-indent: 14px;
      border: 1px solid #dddddd;
      border-radius: 7px;
      &:focus {
        background: white;
      }
    }
    .active-button {
      background: #3468ad;
      color: white;
      cursor: pointer;
    }
    .name {
      width: 329px;
      height: 46px;
      background: #f4f4f4;
    }

    .password {
      width: 329px;
      height: 46px;
      margin-top: 18px;
      background: #f4f4f4;
    }
    .VerificationCode {
      display: flex;
      input {
        margin-top: 15px;
        width: 180px;
        height: 46px;
        background: #f4f4f4;
        &:focus {
          background: white;
        }
      }
      .code-button,
      .code-button-none {
        width: 135px;
        margin-left: 15px;
        height: 50px;
        margin-top: 15px;
        background: #3468ad;
        color: white;
        cursor: pointer;
        font-size: 14px;
      }
      .code-button-none {
        background: #eeeeee;
        color: #1010104d;
        cursor: auto;
      }
    }
    button {
      margin-top: 18px;
      width: 100%;
      height: 46px;
      background: #eeeeee;
      border-radius: 7px 7px 7px 7px;
      border: 1px solid #dddddd;
    }
  }
  .careful {
    position: absolute;
    font-size: 10px;
    text-align: center;
    bottom: 16px;
    color: #c8c8ca;
    width: 100%;
    text-overflow: hidden;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>