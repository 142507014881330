<template>
  <div class="register">
    <div class="header">
      <div>用户名注册</div>
    </div>
    <div class="user-box">
      <input class="name" v-model="userName" placeholder="用户名" type="text" />
      <div class="VerificationCode">
        <input placeholder="验证码" v-model="codeValue" type="text" />
         <img :src="codeimg" alt="" @click="Codeget" />
      </div>
      <div class="password-box">
        <input
          class="password"
          v-model="password"
          placeholder="密码"
          type="password"
          ref="pasword"
        />
        <i @click="showeye" class="iconfont" :class="psdflag?'icon-biyan':'icon-yanjing_xianshi_o'"></i>
      </div>
      <button
        :class="!buttonflag ? 'active-button' : null"
        :disabled="buttonflag"
        @click="register"
      >
        注册
      </button>
      <div class="operation">
        <div @click="gootherpage('signin')">
          已有账户，<span>马上登录</span>
        </div>
      </div>
    </div>
    <div class="careful">注册登录即代表同意 《服务协议》 和《隐私政策》</div>
  </div>
</template>

<script>
import {getVerificationCode,getregister} from "@/api"
export default {
  name: "register",
  data() {
    return {
      buttonflag: true,
      psdflag:true,
      userName: "",
      password: "",
      codeValue: "",
      codeKey:"",
      codeimg:"",
      method:0,
      siteId:'7',
    };
  },
  methods: {
    gootherpage(value) {
      this.$emit("showotherpage", value);
    },
    showeye(){
        this.psdflag=!this.psdflag
        if(this.psdflag){
             this.$refs.pasword.type='password'
        }else{
            this.$refs.pasword.type='text'
        }
    },
    register() {
      let { userName,password,codeValue,codeKey,method,siteId} = this
      let form = {
        userName,password,codeValue,codeKey,method,siteId
      }
      console.log(form);
       getregister(form).then(res=>{
        console.log(res);
      }).catch(err=>{
        console.log('注册失败回调',err);
      })
    },
    Codeget(){
      getVerificationCode().then((res) => {
        this.codeimg=res.data.data
        this.codeKey=res.data.key
      }).catch(err=>{
        console.log('验证码获取错误',err);
      })
    }
  },
  mounted(){
    this.Codeget()
  },
  computed:{
    userdata:function(){
      let {name,password,VerCode} = this
      return {name,password,VerCode}
    }
  },
  watch:{
    userdata(value){
      let {name,password,VerCode} = value
      if(name!=''&&password!=''&&VerCode!=''){
        this.buttonflag = false
      }else{
        this.buttonflag = true
      }
    }
  }
};
</script>

<style lang="less" scoped>
.register {
  position: relative;
  height: 100%;
  width: 100%;
  .header {
    display: flex;
    margin: 0 47px;
    border-bottom: 1px solid #eeeeee;
    div {
      margin-top: 45px;
      padding-bottom: 21px;
      border-bottom: 1px solid #333333;
    }
  }
  .user-box {
    margin: 45px 40px 0 47px;
    input {
      outline: none;
      text-indent: 14px;
      border: 1px solid #dddddd;
      border-radius: 7px;
    }
    .name {
      width: 329px;
      height: 46px;
      background: #f4f4f4;
    }
    .password-box{
        position: relative;
            .password {
      width: 279px;
      height: 46px;
      margin-top: 18px;
      background: #f4f4f4;
      padding-right: 50px;
    }
    .iconfont{
        position: absolute;
        right: 15px;
        top: 33px;
        cursor: pointer;
    }
    }
    .VerificationCode {
      display: flex;
      input {
        margin-top: 15px;
        width: 180px;
        height: 46px;
        background: #f4f4f4;
      }
      img {
        width: 117px;
        margin-left: 21px;
        margin-top: 15px;
      }
    }
    button {
      margin-top: 18px;
      width: 100%;
      height: 46px;
      background: #eeeeee;
      border-radius: 7px 7px 7px 7px;
      border: 1px solid #dddddd;
    }
    .active-button {
      cursor: pointer;
      color: white;
      background: #3468ad;
    }
    .operation {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
      width: 97.5%;
      font-size: 14px;
      color: #9fa19f;
      div {
        margin-left: 10px;
        cursor: pointer;
        span {
          color: black;
        }
      }
    }
  }
  .careful {
    position: absolute;
    font-size: 10px;
    text-align: center;
    bottom: 16px;
    color: #c8c8ca;
    width: 100%;
    text-overflow: hidden;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>